<template>
  <div class="sticky-header-page-container-cards">
    <header class="sticky-header">
      <h1>Card Layout</h1>
    </header>
    <main class="card-container">
      <div class="card" v-for="i in 20" :key="i">Card{{ i }}</div>
    </main>
  </div>
</template>

<script>
export default {
  name: 'Cards',
};
</script>

<style scoped>
.sticky-header-page-container-cards {
  height: 33vh;
  background-color: #393db4;
  border: solid 5px var(--text-color);
  width: 100%;
  margin: 0px auto;
  border-radius: 5px;
  overflow-y: scroll;
}

.sticky-header {
  position: sticky;
  top: 0;
  background: linear-gradient(90deg, #008dff, #ff00cd);
  padding-left: 15px;
  text-align: center;
  /* border-radius: solid; */
  z-index: 1000;
}

.card-container {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: center;
}

.card {
  flex-basis: 20%;
  padding: 30px;
  background-color: #ff00c0;
  text-align: center;
}
</style>
