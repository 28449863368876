<template>
  <div class="GridLayout-component">
    <header class="sticky-header">
      <h1>Grid Layout</h1>
    </header>
    <div
      class="grid-container"
      :style="{ gridTemplateColumns: `repeat(${columns}, 1fr)` }"
    >
      <div class="grid-item" v-for="i in 42" :key="i">{{ i }}</div>
    </div>
    <div class="controls">
      <p>
        Add or Remove Grid columns:
        <span
          ><mark
            ><strong>{{ this.columns }}</strong> columns</mark
          >
          <button @click="increaseColumns" class="btn">Add column</button>
          <button @click="decreaseColumns" class="btn">Remove column</button>
          <button @click="resetColumns" class="btn">Reset columns</button>
        </span>
      </p>
      <p>
        <code>grid-template-columns:</code>repeat<mark
          ><strong>({{ this.columns }} </strong></mark
        >, 1fr)
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GridLayout',
  data() {
    return {
      columns: 4,
      msg: '',
    };
  },
  methods: {
    increaseColumns() {
      this.columns += 1;
    },
    decreaseColumns() {
      if (this.columns > 1) {
        this.columns -= 1; // Prevent going below 1 column
      }
    },
    resetColumns() {
      this.columns = 4;
    },
  },
};
</script>

<style scoped>
.GridLayout-component {
  border: solid 5px;
  border-radius: 5px;
}
.sticky-header {
  position: sticky;
  top: 0;
  background: linear-gradient(160deg, #ff00cd, #fffb00);
  padding-left: 15px;
  text-align: center;
  /* border-radius: solid; */
  z-index: 1000;
  box-shadow: var(--text-color) 0px 1px 1px 0px;
}
.grid-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  height: 300px;
  overflow-y: scroll;
  /* padding: 1em 4em; */
  padding: 15px 20px;
  background-color: var(--page-color);
  /* background: linear-gradient(160deg, #a81a8d, #59036f); */

  .grid-item {
    /* background-color: var(--page-color); */
    background: linear-gradient(160deg, #fffb00, #ff00cd);
    padding: 20px;
    text-align: center;
    font-size: 1em;
    box-shadow: var(--text-color) 1px 1px 1px 0px;
  }
}
.controls {
  text-align: center;
  .btn {
    border-radius: 5px;
    margin-left: 10px;
    background-color: aquamarine;
    border-color: var(--text-color);
    &:hover {
      cursor: pointer;
    }
  }
}
</style>
