<template>
  <div class="masonry-container-conponent">
    <div class="masonry-container">
      <div
        class="masonry-item"
        v-for="(item, index) in masonryItems"
        :key="index"
        :style="{ gridRowEnd: `span ${item.span}` }"
      >
        {{ item.content }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MasonryGridLayout',
  data() {
    return {
      masonryItems: [],
    };
  },
  created() {
    const itemCount = 42; // Set the desired number of items
    for (let i = 1; i <= itemCount; i++) {
      this.masonryItems.push({
        content: `Item ${i}`,
        span: Math.floor(Math.random() * 4) + 1, // Random span between 1 and 4
      });
    }
  },
};
</script>

<style scoped>
.masonry-container-conponent {
  border-radius: 5px;
  border: solid 5px;

  .masonry-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 10px;
    height: 300px;
    overflow-y: scroll;
    /* margin: 0em 1em; */
    padding: 1em 1em;
    /* background: #01bceb; */
    background: linear-gradient(180deg, #01bceb, #ffffff);
  }

  .masonry-item {
    background-color: #ff5c9c;
    padding: 20px;
    text-align: center;
    border: solid 1px #ccc;
    border-radius: 5px;
    box-shadow: #000 1px 1px 1px 0px;
  }
}
</style>
