<template>
  <div class="split-screen-container">
    <div class="panel panel-1">
      <h2>Panel 1</h2>
      <p>flex: 1</p>
    </div>
    <div class="panel panel-2" :style="{ flex: `${flexValue}` }">
      <h2>Panel 2</h2>
      <p>flex: {{ this.flexValue }}</p>
      <div class="controls">
        <button class="btn" @click="growPanelFlex()">grow</button>
        <button class="btn" @click="shrinkPanelFlex()">shrink</button>
        <button class="btn" @click="resetPanelFlex()">Reset</button>
      </div>
    </div>
    <div class="panel panel-3">
      <h2>Panel 3</h2>
      <p>flex: 1</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SplitScreenLayout',
  data() {
    return {
      flexValue: 1,
    };
  },
  methods: {
    growPanelFlex() {
      this.flexValue += 1;
    },
    shrinkPanelFlex() {
      if (this.flexValue > 1) {
        this.flexValue -= 1;
      }
    },
    resetPanelFlex() {
      this.flexValue = 2;
    },
  },
};
</script>

<style scoped>
.split-screen-container {
  display: flex;
  height: 300px;
  border: solid 5px;
  border-radius: 5px;
  /* overflow: hidden; */

  .panel {
    flex: 1;
    padding: 20px;
    text-align: center;
    /* color: #fff; */
  }

  .panel-1 {
    background-color: #5056ff;
  }

  .panel-2 {
    background-color: #008dff;
    .controls {
      .btn {
        margin: 2px;
        border-radius: 5px;
        background-color: yellow;
        border-color: #000;
        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  .panel-3 {
    background-color: #ff00cd;
  }
}
</style>
