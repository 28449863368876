<template>
  <div class="sticky-footer-page-container">
    <section class="content-section">
      <p>Your content goes here.</p>
      <mark>Scroll to see the sticky footer in action.</mark>
    </section>
    <section class="content-section">
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam ultricies
        turpis non neque bibendum, eget aliquam magna vehicula.
      </p>
      <p>Vestibulum auctor diam in lorem cursus, in sagittis orci dignissim.</p>
    </section>
    <section class="content-section">
      <p>
        Suspendisse potenti. Nulla facilisi. Pellentesque id libero in odio
        consequat ultricies.
      </p>
      <p>Donec eu libero vulputate, viverra est a, tristique eros.</p>
    </section>
    <footer class="sticky-footer">
      <h1>Sticky Footer</h1>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'StickyFooter',
};
</script>

<style scoped>
.sticky-footer-page-container {
  height: 33vh;
  background-color: #393db4;
  border: solid 5px var(--text-color);
  width: 100%;
  margin: 0px auto;
  border-radius: 5px;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.sticky-footer {
  position: sticky;
  bottom: 0;
  background: linear-gradient(90deg, #008dff, #ff00cd);
  padding-right: 15px;
  text-align: right;
  z-index: 1000;
}

.content-section {
  padding: 20px;
  background-color: var(--page-color);
  width: 75%;
  margin: 20px auto;
  border-radius: 5px;
}
</style>
