// vue babel plugin doesn't support pragma replacement
import { mdx } from '@mdx-js/vue'
let h;
/* @jsxRuntime classic */
/* @jsx mdx */
import StickyHeader from '../components/posts/Exploring_Well_Known_CSS_Layouts/StickyHeader.vue';
import StickyFooter from '../components/posts/Exploring_Well_Known_CSS_Layouts/StickyFooter.vue';
import StickyHeaderCards from '../components/posts/Exploring_Well_Known_CSS_Layouts/StickyHeaderCards.vue';
import SidebarLayout from '../components/posts/Exploring_Well_Known_CSS_Layouts/SidebarLayout.vue';
import GridLayout from '../components/posts/Exploring_Well_Known_CSS_Layouts/GridLayout.vue';
import MasonryGridLayout from '../components/posts/Exploring_Well_Known_CSS_Layouts/MasonryGridLayout.vue';
import SplitScreenLayout from '../components/posts/Exploring_Well_Known_CSS_Layouts/SplitScreenLayout.vue';
import LayoutBasics from '../components/posts/HTML_and_Semantics_Building_Meaningful_Web_Content/LayoutBasics.vue';


const layoutProps = {
  
};
const MDXLayout = "wrapper"
function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h4 id="table-of-contents">Table of contents:</h4>
    <ol>
      <li parentName="ol"><a parentName="li" {...{
          "href": "#introduction"
        }}>{`Introduction`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "#sticky-header-layout"
        }}>{`Sticky Header Layout`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "#sticky-footer-layout"
        }}>{`Sticky Footer Layout`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "#card-layout"
        }}>{`Card Layout`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "#sidebar-layout"
        }}>{`Sidebar Layout`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "#grid-layout"
        }}>{`Grid Layout`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "#masonry-grid-layout"
        }}>{`Masonry Grid Layout`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "#split-screen-layout"
        }}>{`Split Screen Layout`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "#the-holy-grail-layout"
        }}>{`The Holy Grail Layout`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "#conclusion"
        }}>{`Conclusion`}</a></li>
    </ol>
    <h2 id="introduction">
  Introduction
  <a href="#table-of-contents">↑ go up</a>
    </h2>
    <p>{`In this post, we’ll explore some of the most well-known CSS layouts used in the industry today.
From simple flexbox designs to more complex structures like the Holy Grail, these examples will give
you a solid foundation for building flexible, responsive, and visually appealing web layouts.`}</p>
    <p>{`Let's dive into the different layouts, ordered from the easiest to the most challenging.`}</p>
    <h2 id="sticky-header-layout">
  Sticky Header Layout
  <a href="#table-of-contents">↑ go up</a>
    </h2>
    <p>{`Sticky headers remain visible at the top of the page while users scroll through content, providing
easy access to navigation elements or important information.`}</p>
    <StickyHeader mdxType="StickyHeader" />
    <h4>{`HTML`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<header class="sticky-header">
  <h1>Sticky Header</h1>
</header>
<section>
  <p>Your content goes here.</p>
</section>
`}</code></pre>
    <h4>{`CSS`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`.sticky-header {
  position: sticky;
  top: 0;
  background-color: #333;
  color: white;
  padding: 10px;
}
`}</code></pre>
    <h2 id="sticky-footer-layout">
  Sticky Footer Layout
  <a href="#table-of-contents">↑ go up</a>
    </h2>
    <p>{`This layout ensures that the footer stays at the bottom of the viewport unless there is enough content to push it down.`}</p>
    <StickyFooter mdxType="StickyFooter" />
    <h4>{`HTML`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<div class="content">
  <p>Your main content goes here.</p>
</div>
<footer class="sticky-footer">
  <p>Sticky Footer</p>
</footer>
`}</code></pre>
    <h4>{`CSS`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`body,
html {
  height: 100%;
}

.content {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.sticky-footer {
  margin-top: auto;
  background-color: #333;
  color: white;
  text-align: center;
  padding: 10px;
}
`}</code></pre>
    <h2 id="card-layout">
  Card Layout
  <a href="#table-of-contents">↑ go up</a>
    </h2>
    <p>{`A card layout is a versatile design commonly used in blogs, portfolios, and e-commerce websites.
It uses `}<inlineCode parentName="p">{`flexbox`}</inlineCode>{` to create flexible, responsive cards that adjust their size depending on the screen.`}</p>
    <StickyHeaderCards mdxType="StickyHeaderCards" />
    <h4>{`HTML`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<div class="card-container">
  <div class="card">Card 1</div>
  <div class="card">Card 2</div>
  <div class="card">Card 3</div>
</div>
`}</code></pre>
    <h4>{`CSS`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`.card-container {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: center;
}

.card {
  flex-basis: 20%;
  padding: 30px;
  background-color: #f2f2f2;
  text-align: center;
}
`}</code></pre>
    <h2 id="sidebar-layout">
  Sidebar Layout
  <a href="#table-of-contents">↑ go up</a>
    </h2>
    <p>{`The sidebar layout is a classic design choice that allows for easy navigation and additional
content display alongside the main content area.`}</p>
    <SidebarLayout mdxType="SidebarLayout" />
    <h4>{`HTML`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<div class="sidebar-layout">
  <aside class="sidebar">
    <h3>Sidebar Title</h3>
    <ul>
      <li><a href="#link1">Link 1</a></li>
      <li><a href="#link2">Link 2</a></li>
      <li><a href="#link3">Link 3</a></li>
    </ul>
  </aside>
  <main class="main-content">
    <h1>Main Content Title</h1>
    <p>This is where your main content goes.</p>
  </main>
</div>
`}</code></pre>
    <h4>{`CSS`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`.sidebar-layout {
  display: flex;
}

.sidebar {
  width: 30%;
  padding: 10px;
  background-color: #f8f8f8;
  border-right: 1px solid #ccc;
}

.main-content {
  flex-grow: 1;
  padding: 10px;
}
`}</code></pre>
    <h2 id="grid-layout">
  Grid Layout
  <a href="#table-of-contents">↑ go up</a>
    </h2>
    <p>{`CSS Grid offers a powerful layout system that allows developers to create complex designs with ease.`}</p>
    <p><em parentName="p">{`While the `}<inlineCode parentName="em">{`grid`}</inlineCode>{` Layout focuses on creating a flexible structure of rows and columns to manage the overall layout of a page,
the `}<strong parentName="em">{`Card Layout`}</strong>{` is more component-centric, where each card acts as an individual container for different content types,
allowing for independent manipulation and scaling.`}</em></p>
    <GridLayout mdxType="GridLayout" />
    <h4>{`HTML`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<div class="grid-container">
  <div class="grid-item">1</div>
  <div class="grid-item">2</div>
  <div class="grid-item">3</div>
  <div class="grid-item">4</div>
</div>
`}</code></pre>
    <h4>{`CSS`}</h4>
    <p>{`You can change the `}<strong parentName="p"><mark>{`4`}</mark></strong>{` in `}<inlineCode parentName="p">{`grid-template-columns: repeat(4, 1fr);`}</inlineCode>{` to change the number of columns`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`.grid-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
}

.grid-item {
  background-color: #f2f2f2;
  padding: 20px;
  text-align: center;
}
`}</code></pre>
    <h2 id="masonry-grid-layout">
  Masonry Grid Layout
  <a href="#table-of-contents">↑ go up</a>
    </h2>
    <p>{`The Masonry grid layout allows items to be positioned in a way that resembles
a brick wall with varying heights and widths.`}</p>
    <MasonryGridLayout mdxType="MasonryGridLayout" />
    <h4>{`HTML`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<div class="masonry-container">
  <div class="masonry-item" style="grid-row-end: span 2;">Item 1</div>
  <div class="masonry-item" style="grid-row-end: span 3;">Item 2</div>
  <div class="masonry-item" style="grid-row-end: span 1;">Item 3</div>
  <div class="masonry-item" style="grid-row-end: span 4;">Item 4</div>
  <div class="masonry-item" style="grid-row-end: span 2;">Item 5</div>
  <div class="masonry-item" style="grid-row-end: span 1;">Item 6</div>
  <div class="masonry-item" style="grid-row-end: span 3;">Item 7</div>
  <div class="masonry-item" style="grid-row-end: span 2;">Item 8</div>
  <div class="masonry-item" style="grid-row-end: span 1;">Item 9</div>
  <div class="masonry-item" style="grid-row-end: span 1;">Item 10</div>
</div>
`}</code></pre>
    <h4>{`CSS`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`.masonry-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  gap: 10px;
}

.masonry-item {
  background-color: #f8f8f8;
  padding: 20px;
  text-align: center;
  border: solid 1px #ccc;
  border-radius: 5px;
}
`}</code></pre>
    <h2 id="split-screen-layout">
  Split Screen Layout
  <a href="#table-of-contents">↑ go up</a>
    </h2>
    <p>{`The split-screen layout divides the screen into two or more sections, allowing different content
to be displayed side by side.`}</p>
    <SplitScreenLayout mdxType="SplitScreenLayout" />
    <p>{`The `}<inlineCode parentName="p">{`flex`}</inlineCode>{` property becomes handy here as it is the only value that you have to update if you want to increase or decrease
the width of one of these panels.`}</p>
    <h4>{`HTML`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<div class="split-screen-container">
  <div class="panel panel-1">
    <h2>Panel 1</h2>
    <p>Content for the first panel.</p>
  </div>
  <div class="panel panel-2">
    <h2>Panel 2</h2>
    <p>Content for the second panel.</p>
  </div>
  <div class="panel panel-3">
    <h2>Panel 3</h2>
    <p>Content for the third panel.</p>
  </div>
</div>
`}</code></pre>
    <h4>{`CSS`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`.split-screen-container {
  display: flex;
  height: 100vh;
  overflow: hidden;
}

.panel {
  flex: 1;
  padding: 20px;
  text-align: center;
  color: #fff;
}

.panel-1 {
  background-color: #393db4;
}

.panel-2 {
  background-color: #008dff;
}

.panel-3 {
  background-color: #ff00cd;
}
`}</code></pre>
    <h2 id="the-holy-grail-layout">
  The Holy Grail Layout
  <a href="#table-of-contents">↑ go up</a>
    </h2>
    <p>{`The Holy Grail layout places a header at the top, a footer at the bottom, a main content area in the center,
and sidebars on the left and right.`}</p>
    <LayoutBasics mdxType="LayoutBasics" />
    <h4>{`HTML`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<html>
  <body>
    <header>
      <h1>Header</h1>
    </header>
    <nav>Left Sidebar</nav>
    <main>Main content</main>
    <section>Right Sidebar</section>
    <footer>Footer</footer>
  </body>
</html>
`}</code></pre>
    <h4>{`CSS`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`.body {
  display: grid;
  height: 100vh;
  grid-template: auto 1fr auto / auto 1fr auto;
}

header {
  background: lightpink;
  grid-column: 1 / 4;
  padding-left: 10px;
}

nav {
  background: lightblue;
  grid-column: 1 / 2;
}

main {
  background: coral;
  grid-column: 2 / 3;
  padding: 15px;
}

section {
  background: yellow;
  grid-column: 3 / 4;
}

footer {
  background: wheat;
  padding: 2rem;
  text-align: center;
  grid-column: 1 / 4;
}

.left-sidebar,
.right-sidebar {
  padding: 1rem;
}
`}</code></pre>
    <h2 id="conclusion">
  Conclusion
  <a href="#table-of-contents">↑ go up</a>
    </h2>
    <p>{`Mastering various CSS layouts is crucial for any developer aiming to create engaging
and effective user experiences. By exploring these well known layouts, ranging from card designs to the classic Holy Grail, you can enhance your skills and implement versatile solutions in your projects.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;

export default {
  name: 'Mdx',
  inject: {
    $mdxComponents: {
      default: () => () => ({})
    }
  },
  computed: {
    components() {
      return this.$mdxComponents()
    }
  },
  render(createElement) {
    h = mdx.bind({ createElement, components: this.components })
    return MDXContent({ components: this.components })
  }
}
   