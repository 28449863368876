<template>
  <div>
    <div class="sidebar-layout">
      <aside class="sidebar">
        <h3>Sidebar</h3>
        <ul>
          <li>
            <router-link to="/post/Exploring_Well_Known_CSS_Layouts"
              >link 1</router-link
            >
          </li>
          <li>
            <router-link to="/post/Exploring_Well_Known_CSS_Layouts"
              >link 2</router-link
            >
          </li>
          <li>
            <router-link to="/post/Exploring_Well_Known_CSS_Layouts"
              >link 3</router-link
            >
          </li>
        </ul>
      </aside>
      <main class="main-content">
        <h1>Main Content</h1>
        <p>This is where your main content goes.</p>
      </main>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SidebarLayout',
};
</script>

<style scoped>
.sidebar-layout {
  display: flex;
  height: 300px;
  border: solid 5px;
  gap: 2px;
  border-radius: 5px;
  /* overflow: hidden; */

  .sidebar {
    width: 30%;
    padding: 10px;
    /* background-color: #f8f8f8; */
    border-right: 1px solid #ccc;
    background-color: #ffc609;
    height: auto;
  }
  .main-content {
    background: linear-gradient(90deg, #ffc609, #ffc609, #ff00cc);
    flex-grow: 1;
    padding: 10px;
    margin: 0;
    height: auto;
  }
}
</style>
